import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/blueticklogo.png";
import Footer from "../Components/Footer";
import Headers from "../Components/Headers";

const Login = () => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");

	return (
		<div className="">
			<Headers></Headers>
			<div
				className="bg-gray-100 flex items-center justify-center py-4"
				id="main-content"
			>
				<div className="flex items-center justify-center  w-full  ">
					<form className="bg-white  rounded-lg shadow p-4 md:p-8 m-4 w-full max-w-[400px]">
						<h2 className="text-2xl font-bold mb-8 text-center text-zinc-800 ">
							Login
						</h2>
						<div className="mb-4">
							<label
								htmlFor="email"
								className="block text-zinc-700  text-sm font-bold mb-2"
							>
								Email
							</label>
							<input
								type="email"
								id="email"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								className="shadow appearance-none border rounded w-full py-2 px-3 text-zinc-700 dark:text-zinc-300 leading-tight focus:outline-none focus:shadow-outline"
								placeholder="Email"
								required
							/>
						</div>
						<div className="mb-6">
							<label
								htmlFor="password"
								className="block text-zinc-700 	 text-sm font-bold mb-2"
							>
								Password
							</label>
							<input
								type="password"
								id="password"
								value={password}
								onChange={(e) => setPassword(e.target.value)}
								className="shadow appearance-none border rounded w-full py-2 px-3 text-zinc-700 dark:text-zinc-300 leading-tight focus:outline-none focus:shadow-outline"
								placeholder="Password"
								required
							/>
						</div>
						<div className="mb-6 flex items-center justify-end">
							<Link
								to="/forget-password"
								className="font-bold text-sm text-blue-500 hover:text-blue-800 dark:hover:text-blue-300"
							>
								Forgot password?
							</Link>
						</div>
						<div className="space-y-4">
							{/* Login Button */}
							<button
								className="w-full bg-blue-600 hover:bg-blue-800 text-white font-medium py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-offset-2"
								type="submit"
							>
								Login
							</button>

							{/* OR Divider */}
							<div className="flex items-center justify-center space-x-4">
								<hr className="flex-1 border-gray-300" />
								<span className="text-gray-500 text-sm font-medium border-2 p-2 rounded-full">
									OR
								</span>
								<hr className="flex-1 border-gray-300" />
							</div>

							{/* Google Sign-In Button */}
							<button
								className="w-full flex items-center justify-center bg-red-600 hover:bg-red-700 text-white font-medium py-2	 rounded-lg focus:outline-none focus:ring-2 focus:ring-red-300 focus:ring-offset-2"
								type="button"
							>
								<img
									src="https://kgo.googleusercontent.com/profile_vrt_raw_bytes_1587515358_10512.png"
									alt="Google Icon"
									className="w-7 h-7 mr-3"
								/>
								Sign in with Google
							</button>
						</div>

						<div className="text-center mt-2">
							<p className="text-zinc-600 text-sm">
								Don't have an account?{" "}
								<Link
									to="/register"
									className="text-blue-500 hover:text-blue-800 dark:hover:text-blue-300"
								>
									Signup
								</Link>
							</p>
						</div>
					</form>
				</div>
			</div>
			<Footer></Footer>
		</div>
	);
};

export default Login;
