import React from "react";

import logo from "../assets/blueticklogo.png";
import { Link } from "react-router-dom";
const Headers = ({ children }) => {
	return (
		<div className="flex justify-between items-center mx-2 md:mx-16 lg:mx-32 mb-10 mt-3">
			<div className="flex justify-center items-center mx-2">
				<Link to="/">
					<img src={logo} className="w-36 md:w-48" alt="Bluetick Logo" />
				</Link>
			</div>
			{children}
		</div>
	);
};

export default Headers;
