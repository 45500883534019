import React from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";

import Application from "./Pages/Application.js";
import Home from "./Pages/Home.js";
import MainPage from "./Pages/MainPage.js";
import Login from "./Pages/Login.js";
import Register from "./Pages/Register.js";
import ForgetPass from "./Pages/ForgetPassword.js";

function App() {
	return (
		<Router>
			<Routes>
				<Route path="/login" element={<Login />} />
				<Route path="/register" element={<Register />} />
				<Route path="/forget-password" element={<ForgetPass />} />
				<Route path="/app" element={<Application />} />
				<Route path="/" element={<Home />} />
				<Route path="/app-submit" element={<MainPage />} />
			</Routes>
		</Router>
	);
}

export default App;
