const Benefits = () => {
	const benefits1 = [
		{
			icon: "https://img.icons8.com/ios-glyphs/30/decision--v1.png",
			title: "Enhanced Data Security",
			description:
				"Ensure the Protection of Your Sales Call Data with Server-Based Security Measures.",
		},
		{
			icon: "https://img.icons8.com/ios-glyphs/30/decision--v1.png",
			title: "Efficient Reference and Analysis",
			description:
				"Streamline Reference and Analysis Tasks with Automatically Generated Detailed Transcripts for Quick Access.",
		},
		{
			icon: "https://img.icons8.com/ios-glyphs/30/decision--v1.png",
			title: "Time-Saving Call Summaries",
			description:
				"Save Time with Concise Call Overviews and Summaries, Providing Quick and Actionable Insights.",
		},
	];
	const benefits2 = [
		{
			icon: "https://img.icons8.com/ios-glyphs/30/decision--v1.png",
			title: "Comprehensive Call Understanding",
			description:
				"Gain a Comprehensive Understanding of Your Sales Calls withIn-Depth Insights, Including Sentiment Analysis and Speech Clarity Checks.",
		},
	];
	return (
		<div className="py-16" data-aos="fade-up">
			<div className="relative px-4 mx-auto max-w-screen-xl sm:px-6 lg:px-8">
				{/* Cards Grid */}
				<div className="relative grid gap-8 sm:grid-cols-2 lg:grid-cols-3">
					{/* benefits 1 */}
					{benefits1.map((elem, index) => (
						<div
							key={index}
							className="hover:scale-105 flex flex-col justify-between overflow-hidden text-left transition-shadow duration-200 bg-white rounded-lg shadow-lg group hover:shadow-xl"
						>
							<div className="p-6">
								<div className="flex items-center justify-center w-12 h-12 mb-4 rounded-full bg-indigo-50">
									<img
										width="40"
										height="40"
										src={elem.icon}
										alt="AI Analysis"
									/>
								</div>
								<h3 className="text-lg font-semibold mb-2">{elem.title}</h3>
								<p className="text-sm text-gray-600 leading-6">
									{elem.description}
								</p>
							</div>
							<div className="w-full h-1 bg-blue-500 scale-x-0 group-hover:scale-x-100 transform origin-left transition-transform duration-300"></div>
						</div>
					))}
				</div>
				<div className="flex flex-col items-center gap-8 mt-10 sm:flex-row sm:justify-center sm:gap-12">
					{/* benefit 4 */}
					{benefits2.map((elem, index) => (
						<div
							key={index}
							className="hover:scale-105 flex flex-col justify-between overflow-hidden text-left transition-shadow duration-200 bg-white rounded-lg shadow-lg group "
						>
							<div className="p-6">
								<div className="flex items-center justify-center w-12 h-12 mb-4 rounded-full bg-indigo-50">
									<img
										width="30"
										height="30"
										src={elem.icon}
										alt="Proctored Exams"
									/>
								</div>
								<h3 className="text-lg font-semibold mb-2">{elem.title}</h3>
								<p className="text-sm text-gray-600 leading-6">
									{elem.description}
								</p>
							</div>
							<div className="w-full h-1 bg-blue-500 scale-x-0 group-hover:scale-x-100 transform origin-left transition-transform duration-300"></div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default Benefits;
