import { useState } from "react";
import Modal from "react-modal";
import { IoClose } from "react-icons/io5";
import sampleAudio from "../../sampleAudio/customer_support.wav";
import sampleAudio1 from "../../sampleAudio/e-commerce-call.wav";

Modal.setAppElement("#root");

const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		borderRadius: "10px",
		border: "none",
		boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.2)",
		maxHeight: "650px",
	},
};
const PreLoadedAudioExmaples = ({ setSelectedSample }) => {
	const [sampleAudioNo, setSampleAudioNo] = useState();
	const samples = [
		{
			name: "example_1",
			src: sampleAudio,
			displayName: "customer_support",
			src_api: "../../sampleAudio/customer_support.wav",
			speaker: 2,
		},
		{
			name: "example_2",
			displayName: "e-commerce-call",
			src: sampleAudio1,
			src_api: "../../sampleAudio/e-commerce-call.wav",
			speaker: 2,
		},
	];

	const [modalIsOpen, setIsOpen] = useState(false);

	function openModal() {
		setIsOpen(true);
		setSampleAudioNo(null);
	}

	function closeModal() {
		setIsOpen(false);
		setSampleAudioNo(null);
	}
	const handleLoadClick = () => {
		if (!sampleAudioNo) return;

		setSelectedSample(samples[sampleAudioNo]);

		setIsOpen(false);
	};
	return (
		<div>
			<button
				onClick={openModal}
				className="text-indigo-600 font-medium hover:underline focus:outline-none mt-2"
			>
				Load Examples
			</button>
			<Modal
				isOpen={modalIsOpen}
				onRequestClose={closeModal}
				style={customStyles}
				contentLabel="Example Modal"
			>
				<div className="py-4  relative bg-white rounded-lg md:p-6 max-w-4xl w-full mx-auto">
					<button
						onClick={closeModal}
						className="absolute top-2 right-3 p-2 rounded-full bg-red-500 text-white font-medium shadow-md hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-red-300 transition-transform transform hover:scale-105"
						aria-label="Close Modal"
					>
						<IoClose size={20} />
					</button>

					<div className="flex flex-col items-center">
						<span className="text-base md:text-xl font-semibold text-black mb-4 text-center">
							Load Example Audio
						</span>

						<div className="overflow-x-auto w-full">
							<table className="table-auto border-collapse text-sm md:text-lg mt-5 w-full">
								<thead>
									<tr className="bg-gray-100 text-left">
										<th className="border border-gray-300 px-2 py-2 md:px-6 md:py-3">
											Select
										</th>
										<th className="border border-gray-300 px-2 py-2 md:px-6 md:py-3">
											Example Audio
										</th>
										<th className="border border-gray-300 px-2 py-2 md:px-6 md:py-3">
											No. of Speakers
										</th>
									</tr>
								</thead>
								<tbody>
									{samples.map((elem, index) => (
										<tr key={index} className="hover:bg-gray-50">
											<td className="border border-gray-300 px-3 py-2 text-center">
												<input
													name="audio"
													type="radio"
													value={index}
													onChange={(e) => setSampleAudioNo(e.target.value)}
													className="cursor-pointer"
												/>
											</td>
											<td className="border border-gray-300 px-3 py-2 cursor-pointer">
												{elem.displayName}
											</td>
											<td className="border border-gray-300 px-3 py-2 text-center">
												{elem.speaker}
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>

						<div className="mt-6">
							<button
								onClick={handleLoadClick}
								className="bg-blue-500 text-white px-4 py-2 md:px-6 md:py-2 rounded-md shadow-md hover:bg-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-300 transition-transform transform hover:scale-105"
							>
								Load
							</button>
						</div>
					</div>
				</div>
			</Modal>
		</div>
	);
};

export default PreLoadedAudioExmaples;
