import React, { useState } from "react";
import { Link } from "react-router-dom";
// import api from "./components/customAxios/Axios";
import logo from "../assets/blueticklogo.png";
import Footer from "../Components/Footer";
import Headers from "../Components/Headers";
// import Header from "./components/Header";
const ForgetPass = () => {
	const [email, setEmail] = useState("");

	return (
		<div className="">
			<Headers>
				<></>
			</Headers>
			<div className="md:min-h-[75vh] min-h-[85vh] flex items-center justify-center bg-zinc-100  p-6 md:p-8">
				<div className="max-w-md w-full md:p-6 p-3 bg-white  rounded-lg shadow-md">
					<h2 className="text-2xl font-bold mb-8 text-center text-zinc-800 ">
						Forgot Password
					</h2>
					<form className="mb-4">
						<label className="block text-zinc-700 d text-sm font-bold mb-2">
							Enter your email address to reset your password
						</label>
						<input
							type="email"
							placeholder="Email"
							onChange={(e) => setEmail(e.target.value)}
							className="shadow appearance-none border rounded w-full my-2 p-3 text-zinc-700 dark:text-zinc-300 leading-tight focus:outline-none focus:shadow-outline"
						/>
						<button
							type="submit"
							className="w-full bg-blue-500 text-white p-2 rounded-md mt-4 hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-400 dark:focus:ring-blue-600"
						>
							Reset Password
						</button>
					</form>
					<p className="text-sm text-center text-zinc-600 dark:text-zinc-400">
						Remember your password?{" "}
						<Link
							to="/login"
							className="text-blue-500 dark:text-blue-400 hover:underline"
						>
							Login
						</Link>
					</p>
				</div>
			</div>
			<Footer></Footer>
		</div>
	);
};

export default ForgetPass;
