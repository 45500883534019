import React from "react";

import TableContent from "../Components/MainPage/TableContent";
import Headers from "../Components/Headers";
import Footer from "../Components/Footer";
const MainPage = () => {
	const storedAnalyzeData = localStorage.getItem("analyzeData");
	const analyzeData = storedAnalyzeData ? JSON.parse(storedAnalyzeData) : null;
	const storedGenerateData = localStorage.getItem("generateData");

	const generateData = storedGenerateData
		? JSON.parse(storedGenerateData)
		: null;

	return (
		<>
			<Headers>{/* Pass the userProfile here */}</Headers>
			<div className=" mx-auto md:px-9 px-3 bg-white">
				{/* Dashboard Heading */}
				<div className="my-6 text-center">
					<h1 className="text-4xl font-semibold text-gray-800">Dashboard</h1>
				</div>

				{/* Analysis Section */}
				<div className="my-8">
					<h2 className="text-2xl font-medium text-gray-700 mb-4">Analysis</h2>
					<div className=" ">
						<TableContent
							analyzeData={analyzeData}
							generateData={generateData}
						/>
					</div>
				</div>

				{/* Call Summary Section */}
				<div className="my-8">
					<h2 className="text-2xl font-medium text-gray-700 mb-4">
						Call Summary
					</h2>

					{/* Transcript Section */}
					<div className="mb-8">
						<h3 className="text-lg font-medium text-gray-800">1. Transcript</h3>
						<div className="bg-gray-100 shadow-inner  rounded-lg px-4 pb-4 h-60 overflow-auto mt-3">
							{analyzeData?.transcript ? (
								<div className="text-sm text-gray-800 whitespace-pre-line ">
									{analyzeData.transcript
										.split(/(SPEAKER \d+)/)
										.map((segment, index) =>
											segment.startsWith("SPEAKER") ? (
												<span
													key={index}
													className="font-semibold text-blue-600"
												>
													{segment}
												</span>
											) : (
												<span key={index}>{segment.trimStart() + "\n"}</span>
											)
										)}
								</div>
							) : (
								<p className="text-gray-500">No transcript available.</p>
							)}
						</div>
					</div>

					{/* Summary Section */}
					<div>
						<h3 className="text-lg font-medium  text-gray-800">2. Summary</h3>
						<div className="bg-gray-100 shadow-inner rounded-lg p-4 h-60 overflow-auto mt-3">
							{generateData?.data?.summary ? (
								<div className="text-sm text-gray-800 whitespace-pre-line">
									{generateData.data.summary.split("\n").map((line, index) => {
										const [key, value] = line.split(":");
										return (
											<div key={index} className="mb-3">
												<span className="font-semibold text-blue-600">
													{key}:
												</span>{" "}
												<span>{value} </span>
											</div>
										);
									})}
								</div>
							) : (
								<p className="text-gray-500">No summary available.</p>
							)}
						</div>
					</div>
				</div>

				<Footer />
			</div>
		</>
	);
};

export default MainPage;
