import { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { RiUploadCloud2Fill } from "react-icons/ri";

const UploadAudio = ({ setAudioFile, setAudioFiles }) => {
	const [file, setFile] = useState(null);

	const handleChange = (file) => {
		setFile(file);
		setAudioFile(file);
		setAudioFiles(file[0]);
	};

	return (
		<FileUploader
			multiple={true}
			handleChange={handleChange}
			name="file"
			classes="flex flex-col items-center justify-center w-full h-64 border-2 border-dashed border-blue-600 rounded-lg bg-blue-50 p-4"
		>
			<label
				htmlFor="file-upload"
				className="flex flex-col items-center justify-center cursor-pointer"
			>
				<RiUploadCloud2Fill className="text-blue-600 text-5xl mb-3" />
				<span className="text-blue-600 font-medium text-lg">
					Drag and drop or <span className="underline">Browse Audio</span>
				</span>
				<input
					id="file-upload"
					type="file"
					multiple
					className="hidden"
					onChange={handleChange}
				/>
			</label>
		</FileUploader>
	);
};

export default UploadAudio;
