import { useState } from "react";
import { Link } from "react-router-dom";
import AudioContainer from "../Components/Audio/AudioContainer";
import logo from "../assets/blueticklogo.png";
import Footer from "../Components/Footer";
import PreLoadedAudioExmaples from "../Components/Audio/PreLoadedAudioExmaples";
import Headers from "../Components/Headers";

const Application = () => {
	const [selectedSample, setSelectedSample] = useState({});
	return (
		<>
			<div className="">
				<Headers>{/* pass userProfile here */}</Headers>

				<main className="bg-gray-50  py-4">
					<div className="flex flex-wrap items-center justify-center 	min-h-[80vh]">
						<div className="border-2 md:py-5 md:px-6 md:w-[60%] py-3 px-2 bg-white rounded-lg shadow-2xl p-4">
							<h2 className="text-xl md:text-3xl font-bold text-gray-800 mb-4 text-center md:text-left">
								Sales Call Evaluator
							</h2>
							<p className="text-gray-600 mb-6 text-center md:text-left">
								Upload your own audio file or select one from the examples
								provided below.
							</p>
							<PreLoadedAudioExmaples
								setSelectedSample={setSelectedSample}
							></PreLoadedAudioExmaples>
							<AudioContainer
								data={selectedSample}
								clearData={() => setSelectedSample({})}
							/>
						</div>
					</div>
				</main>

				<Footer></Footer>
			</div>
		</>
	);
};

export default Application;
