import React, { useRef } from "react";
import { Link } from "react-router-dom";

import Feature from "../Components/HomePage/Feature";
import Benefits from "../Components/HomePage/Benefits";
import Footer from "../Components/Footer";
import Headers from "../Components/Headers";
const Home = () => {
	const featureRef = useRef(null);
	const benefitsRef = useRef(null);

	const handleFeatureClick = () => {
		featureRef.current?.scrollIntoView({ behavior: "smooth" });
	};

	const handleBenefitsClick = () => {
		benefitsRef.current?.scrollIntoView({ behavior: "smooth" });
	};

	// Button style (assuming you have defined this somewhere)

	return (
		<div className="bg-white ">
			{/* Navigation */}
			<Headers>
				<div className="flex items-center gap-4 md:gap-16 text-sm lg:text-lg">
					<h1
						onClick={handleFeatureClick}
						className="cursor-pointer hidden md:block font-semibold"
					>
						Features
					</h1>
					<h1
						onClick={handleBenefitsClick}
						className="cursor-pointer hidden md:block font-semibold"
					>
						Benefits
					</h1>
					<Link to="/login" className="link-styles">
						<button className="px-2 py-1 md:px-6 md:py-3 w-32 rounded-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-600 hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300">
							Login
						</button>
					</Link>
				</div>
			</Headers>

			{/* Intro */}
			<div className="overflow-x-hidden md:px-5 mx-2 md:mx-16 lg:mx-20">
				<div className="flex flex-col md:flex-row items-center gap-5 md:gap-16 mb-3">
					{/* Text Content */}
					<div className="flex flex-col gap-6 md:w-1/2" data-aos="fade-right">
						<div>
							<h1 className="text-3xl md:text-4xl font-bold text-black mb-4">
								Sales Call Evaluator
							</h1>
							<h2 className="text-2xl md:text-3xl font-semibold text-black">
								Optimize Your Sales Performance with Sales Call Evaluator
							</h2>
						</div>
						<p className="text-gray-600 text-base md:text-lg">
							Empower your sales teams with the cutting-edge Sales Call
							Evaluator. Say goodbye to time-consuming call documentation. Our
							revolutionary tool generates audio call transcripts, simplifying
							the evaluation process and saving valuable time.
						</p>
						<Link to="/app" className="link-styles">
							<button className="px-4 py-2 md:px-6 md:py-3 w-32 rounded-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-600 hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300">
								Try Now
							</button>
						</Link>
					</div>
					{/* Image Section */}
					<div
						className="w-full md:w-1/2  border-2 rounded-md shadow-2xl"
						data-aos="fade-left"
					>
						<img
							className="w-full h-full   "
							src="https://bluetick-website-images.bluetickconsultants.com/generative-ai-website/images/products/salescalleval.webp"
						></img>
					</div>
				</div>
			</div>

			{/* Features */}
			<div
				ref={featureRef}
				className="flex flex-col justify-center items-center mt-20"
			>
				<hr className="m-2 border-gray-400 w-[95%] border-t-2" />
				<h1 className="text-2xl md:text-4xl text-blue-600 font-semibold mt-10 mb-4">
					Features
				</h1>

				<Feature></Feature>
			</div>
			<hr className="m-2 border-gray-400 w-[95%] border-t-2 " />
			{/* Explore */}
			<div className="container-fluid px-0 my-5 pb-5 mt-4">
				<div className="container" style={{ display: "block" }}>
					<div className="row p-5 bg-light align-items-center">
						<div className="col-12 col-md-10 my-2">
							<h1
								className="text-left"
								style={{
									fontSize: "clamp(1.7rem, 4vw, 2rem)",
									fontWeight: "bold",
									color: "#000",
									fontFamily: "sans-serif",
								}}
							>
								Experience the power of Sales Call Evaluator
							</h1>
						</div>
						<div className="col-12 col-md-2 my-2">
							<Link to="/app">
								<button className=" border-2 border-blue-600 text-blue-600 p-2 rounded-md hover:bg-blue-600 hover:text-white">
									Go to App
								</button>
							</Link>
						</div>
					</div>
				</div>
			</div>

			<hr className="m-2 border-gray-400 w-[95%] border-t-2" />
			{/* Benefits */}
			<div
				ref={benefitsRef}
				className="flex flex-col justify-center items-center mt-20"
			>
				<h1 className="text-2xl md:text-4xl text-blue-600 font-semibold mt-10 mb-4">
					Benefits
				</h1>

				<Benefits></Benefits>
			</div>

			{/* Footer */}
			<Footer></Footer>
		</div>
	);
};

export default Home;
