import axios from "axios";

// Create an instance of axios
const API = axios.create({
  baseURL: "https://coops-backend.bluetickconsultants.com:8010/",
  headers: {
    "Content-Type": "application/json",
  },
});

export default API;
