import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/blueticklogo.png";
import Footer from "../Components/Footer";
import Headers from "../Components/Headers";

const Register = () => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");

	return (
		<div>
			<Headers></Headers>
			<div className="bg-gray-100 flex items-center justify-center px-4 sm:px-6 lg:px-8 flex-col py-4">
				<div className="w-full max-w-md space-y-6">
					<div className="bg-white shadow-md rounded-md p-4 pt-3">
						<h2 className="text-2xl font-bold mb-8 text-center text-zinc-800 ">
							Sign Up
						</h2>

						<form className="space-y-4">
							<div>
								<label
									htmlFor="first-name"
									className="block text-zinc-700  text-sm font-bold mb-2"
								>
									First Name
								</label>
								<div className="mt-1">
									<input
										onChange={(e) => setFirstName(e.target.value)}
										name="first_name"
										type="text"
										required
										className="shadow appearance-none border rounded w-full py-2 px-3 text-zinc-700 dark:text-zinc-300 leading-tight focus:outline-none focus:shadow-outline"
										placeholder="First Name"
									/>
								</div>
							</div>

							<div>
								<label
									htmlFor="last-name"
									className="block text-zinc-700  text-sm font-bold mb-2"
								>
									Last Name
								</label>
								<div className="mt-1">
									<input
										onChange={(e) => setLastName(e.target.value)}
										name="last_name"
										type="text"
										required
										className="shadow appearance-none border rounded w-full py-2 px-3 text-zinc-700 dark:text-zinc-300 leading-tight focus:outline-none focus:shadow-outline"
										placeholder="Last Name"
									/>
								</div>
							</div>

							<div>
								<label
									htmlFor="email"
									className="block text-zinc-700  text-sm font-bold mb-2"
								>
									Email
								</label>
								<div className="mt-1">
									<input
										onChange={(e) => setEmail(e.target.value)}
										name="email"
										type="email"
										autoComplete="email"
										required
										className="shadow appearance-none border rounded w-full py-2 px-3 text-zinc-700 dark:text-zinc-300 leading-tight focus:outline-none focus:shadow-outline"
										placeholder="Email"
									/>
								</div>
							</div>

							<div>
								<label
									htmlFor="password"
									className="block text-zinc-700  text-sm font-bold mb-2"
								>
									Password
								</label>
								<div className="mt-1">
									<input
										onChange={(e) => setPassword(e.target.value)}
										name="password"
										type="password"
										autoComplete="new-password"
										required
										className="shadow appearance-none border rounded w-full py-2 px-3 text-zinc-700 dark:text-zinc-300 leading-tight focus:outline-none focus:shadow-outline"
										placeholder="xxxxxxx"
									/>
								</div>
							</div>

							<div>
								<label
									htmlFor="confirm-password"
									className="block text-zinc-700  text-sm font-bold mb-2"
								>
									Confirm Password
								</label>
								<div className="mt-1">
									<input
										onChange={(e) => setConfirmPassword(e.target.value)}
										name="confirm_password"
										type="password"
										autoComplete="new-password"
										required
										className="shadow appearance-none border rounded w-full py-2 px-3 text-zinc-700 dark:text-zinc-300 leading-tight focus:outline-none focus:shadow-outline"
										placeholder="xxxxxxx"
									/>
								</div>
							</div>

							<div className="space-y-4">
								{/* Login Button */}
								<button
									className="w-full bg-blue-600 hover:bg-blue-800 text-white font-medium py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-offset-2"
									type="submit"
								>
									Register
								</button>

								{/* OR Divider */}
								<div className="flex items-center justify-center space-x-4">
									<hr className="flex-1 border-gray-300" />
									<span className="text-gray-500 text-sm font-medium border-2 p-2 rounded-full">
										OR
									</span>
									<hr className="flex-1 border-gray-300" />
								</div>

								{/* Google Sign-In Button */}
								<button
									className="w-full flex items-center justify-center bg-red-600 hover:bg-red-700 text-white font-medium py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-red-300 focus:ring-offset-2"
									type="button"
								>
									<img
										src="https://kgo.googleusercontent.com/profile_vrt_raw_bytes_1587515358_10512.png"
										alt="Google Icon"
										className="w-7 h-7 mr-3"
									/>
									Sign up with Google
								</button>
							</div>
							<div className="text-center">
								<p className="text-zinc-600 text-sm">
									Already have an account?{" "}
									<Link
										to="/login"
										className="text-blue-500 hover:text-blue-800 dark:hover:text-blue-300"
									>
										Login
									</Link>
								</p>
							</div>
						</form>
					</div>
				</div>
			</div>
			<Footer></Footer>
		</div>
	);
};

export default Register;
