import React, { useEffect, useState } from "react";
import TempAudioPlayer from "./TempAudioPlayer";
import UploadAudio from "./FileUploader";
// import "./audioContainer.scss";
import API from "../../AxiosSetup/baseUrl";
import { useLoader } from "../../context/LoaderContext";
const AudioContainer = (props) => {
	const { setLoading } = useLoader();
	const [audioUrl, setAudioUrl] = useState();
	const [speakers, setSpeakers] = useState("");
	const [audioFiles, setAudioFiles] = useState();
	const [analyzeData, setAnalyzeData] = useState();
	const [generateData, setGenerateData] = useState();

	const { src, speaker, src_api, name } = props.data;
	const [binaryData, setBinaryData] = useState(null);
	useEffect(() => {
		if (src) {
			setAudioUrl(null);

			setAudioUrl(src);
			setSpeakers(speaker);
		} else {
			setAudioUrl();
		}
	}, [src, speaker]);

	const handleSetUploadAudioFile = (file) => {
		setAudioUrl(URL.createObjectURL(file[0]));
		props.clearData();
	};

	const handleClearAudio = () => {
		setAudioUrl(null);
	};
	const handleAnalysisSubmit = async () => {
		setLoading(true);

		try {
			// Fetch audio file and return form data
			const createFormData = async () => {
				let formData = new FormData();

				const response = await fetch(src_api);
				if (!response.ok) {
					throw new Error(`Failed to fetch audio: ${response.status}`);
				}
				const arrayBuffer = await response.arrayBuffer();
				formData.append("audio", new Blob([arrayBuffer]), "audio.wav");

				formData.append("name", props.data.name || "example_1");
				formData.append("num_speakers", speakers || 2);

				return formData;
			};
			// API call for audio analysis
			const analyzeAudio = async (formData) => {
				const blob = new Blob([...formData.entries()]);

				// Calculate Content-Length
				const contentLength = blob.size;

				try {
					const response = await API.post("analyze_audio", formData, {
						headers: {
							"Content-Type": "multipart/form-data", // Include multipart headers
							"Content-Length": contentLength, // Explicit Content-Length
						},
					});
					return response.data.data;
				} catch (error) {
					console.error(error.message);
				}
			};

			// API call to generate analysis
			const generateAnalysis = async (analysisData) => {
				const response = await API.post("generate_analysis", {
					raw_data: analysisData.raw_data,
					raw_text: analysisData.raw_text,
					name: props.data.name || "example_1",
					num_speakers: props.data.speaker || 2,
				});
				return response.data;
			};

			// Workflow
			const formData = await createFormData();
			const analysisData = await analyzeAudio(formData);

			setAnalyzeData(analysisData);
			localStorage.setItem("analyzeData", JSON.stringify(analysisData));

			const generatedData = await generateAnalysis(analysisData);
			setGenerateData(generatedData);
			localStorage.setItem("generateData", JSON.stringify(generatedData));

			window.location.href = "#/app-submit";
		} catch (error) {
			console.error("Error:", error);
		} finally {
			setLoading(false);
		}
	};
	return (
		<div className="w-full">
			{audioUrl ? (
				<>
					<div className="mt-4 w-full rounded-lg shadow-2xl  bg-gradient-to-r from-blue-300 via-blue-400 to-blue-500">
						<TempAudioPlayer
							audioUrl={audioUrl}
							clear={handleClearAudio}
							waveStyles={{
								cursorWidth: 1,
								progressColor: "white",
								responsive: true,
								waveColor: "#121640",
								cursorColor: "transparent",
								barWidth: 1,
							}}
							zoom={0}
						/>
					</div>
					<div className="flex flex-col items-center mt-6">
						<label className="text-gray-700 font-medium mb-2">
							Select number of speakers
						</label>
						<input
							type="number"
							value={speakers}
							onChange={(e) => setSpeakers(e.target.value)}
							className="w-20 px-2 py-1 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600"
						/>
						<div className="flex gap-4 mt-4">
							<button
								className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700"
								onClick={handleAnalysisSubmit}
							>
								Submit
							</button>
							<button
								className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-400"
								onClick={handleClearAudio}
							>
								Clear
							</button>
						</div>
					</div>
				</>
			) : (
				<div className="mt-4">
					<UploadAudio
						setAudioFile={handleSetUploadAudioFile}
						setAudioFiles={setAudioFiles}
					/>
				</div>
			)}
		</div>
	);
};

export default AudioContainer;
