const Feature = () => {
	const feature1 = [
		{
			icon: "https://img.icons8.com/ios-glyphs/30/decision--v1.png",
			title: "Custom Question Generation:",
			description:
				"Enter Job Descriptions, Get AI-Crafted Interview Questions - Tailored to Assess Key Skills and Experience Levels.",
		},
		{
			icon: "https://img.icons8.com/ios-glyphs/30/decision--v1.png",
			title: "Data Security",
			description:
				"ecure Your Sales Call Data on Your Server for Top-Level Data Security.",
		},
		{
			icon: "https://img.icons8.com/ios-glyphs/30/decision--v1.png",
			title: "Call Transcripts",
			description:
				"Automatically Generate Detailed Transcripts of Your Sales Calls for Easy Reference and Analysis.",
		},
	];
	const feature2 = [
		{
			icon: "https://img.icons8.com/ios-glyphs/30/decision--v1.png",
			title: "Call Overview and Summary",
			description:
				"Obtain Concise Overviews and Summaries of Your Calls, Saving You Time and Providing Quick Insights.",
		},
		{
			icon: "https://img.icons8.com/ios-glyphs/30/decision--v1.png",
			title: "In-Depth Insights",
			description:
				"Access a Wealth of Insights, Including Sentiment Analysis Speech Clarity, Profanity Checks, and More.",
		},
	];
	return (
		<div className="py-16" data-aos="fade-up">
			<div className="relative px-4 mx-auto max-w-screen-xl sm:px-6 lg:px-8">
				{/* Cards Grid */}
				<div className="relative grid gap-8 sm:grid-cols-2 lg:grid-cols-3">
					{/* Feature 1 */}
					{feature1.map((elem, index) => (
						<div
							key={index}
							className="hover:scale-105 flex flex-col justify-between overflow-hidden text-left transition-shadow duration-200 bg-white rounded-lg shadow-lg group hover:shadow-xl"
						>
							<div className="p-6">
								<div className="flex items-center justify-center w-12 h-12 mb-4 rounded-full bg-indigo-50">
									<img
										width="40"
										height="40"
										src={elem.icon}
										alt="AI Analysis"
									/>
								</div>
								<h3 className="text-lg font-semibold mb-2">{elem.title}</h3>
								<p className="text-sm text-gray-600 leading-6">
									{elem.description}
								</p>
							</div>
							<div className="w-full h-1 bg-blue-500 scale-x-0 group-hover:scale-x-100 transform origin-left transition-transform duration-300"></div>
						</div>
					))}
				</div>
				<div className="flex flex-col items-center gap-8 mt-10 sm:flex-row sm:justify-center sm:gap-12">
					{/* Feature 4 */}
					{feature2.map((elem, index) => (
						<div
							key={index}
							className="hover:scale-105 flex flex-col justify-between overflow-hidden text-left transition-shadow duration-200 bg-white rounded-lg shadow-lg group "
						>
							<div className="p-6">
								<div className="flex items-center justify-center w-12 h-12 mb-4 rounded-full bg-indigo-50">
									<img
										width="30"
										height="30"
										src={elem.icon}
										alt="Proctored Exams"
									/>
								</div>
								<h3 className="text-lg font-semibold mb-2">{elem.title}</h3>
								<p className="text-sm text-gray-600 leading-6">
									{elem.description}
								</p>
							</div>
							<div className="w-full h-1 bg-blue-500 scale-x-0 group-hover:scale-x-100 transform origin-left transition-transform duration-300"></div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default Feature;
