import { createContext, useContext, useState } from "react";
import { MutatingDots } from "react-loader-spinner";
const Loader = () => {
	return (
		<div className="fixed top-0 left-0 h-screen w-screen flex justify-center items-center backdrop-blur-sm">
			<MutatingDots
				visible={true}
				height="100"
				width="100"
				color="#3B82F6"
				secondaryColor="#3B82F6"
				radius="12.5"
				ariaLabel="mutating-dots-loading"
			/>
		</div>
	);
};

const LoaderContext = createContext();

export const useLoader = () => {
	const context = useContext(LoaderContext);
	if (!context) {
		throw new Error("useLoader must be used within a LoaderProvider");
	}
	return context;
};

export const LoaderProvider = ({ children }) => {
	const [loading, setLoading] = useState(false);
	return (
		<LoaderContext.Provider value={{ setLoading }}>
			{children}
			{loading && <Loader />}
		</LoaderContext.Provider>
	);
};
