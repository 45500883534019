import React, { useState, useEffect, useRef, Fragment } from "react";
import WaveSurfer from "wavesurfer.js";

import { FaPlay, FaUndo, FaRedo, FaTrash } from "react-icons/fa";
import { FaPause } from "react-icons/fa";

// import "./tempAudio.css";

export default function TempAudioPlayer(props) {
	const waveformRef = useRef();
	const trackRef = useRef(); // Separated track playing from waveplayer to support bigger audio files
	const [waveSurfer, setWaveSurfer] = useState(null); // Holds the reference to created wavesurfer object

	const [playingAudio, setPlayingAudio] = useState(false);
	const [playBackSpeed, setPlayBackSpeed] = useState(1);
	const [currentTime, setCurrentTime] = useState(0);
	const [duration, setDuration] = useState(0);

	const formatTime = (seconds) => {
		const minutes = Math.floor(seconds / 60);
		const secondsLeft = Math.floor(seconds % 60);
		return `${minutes.toString().padStart(2, "0")}:${secondsLeft
			.toString()
			.padStart(2, "0")}`;
	};

	const playBackSpeedOptions = props.playBackSpeedOptions ?? [
		0.5, 1, 1.2, 1.5, 2,
	];

	const playAudio = () => {
		if (!props.hideWave) waveSurfer.play();
		else trackRef.current.play();
		setPlayingAudio(true);
	};

	const pauseAudio = () => {
		if (!props.hideWave) waveSurfer.pause();
		else trackRef.current.pause();
		setPlayingAudio(false);
	};

	const changePlaybackSpeed = () => {
		const newSpeed = playBackSpeed === 4 ? 0 : playBackSpeed + 1;
		setPlayBackSpeed(newSpeed);
		trackRef.current.playbackRate = playBackSpeedOptions[newSpeed];
	};

	const seekAudioFifteenSeconds = (ahead) => {
		if (ahead) trackRef.current.currentTime += 15;
		else trackRef.current.currentTime -= 15;
	};

	useEffect(() => {
		if (waveformRef.current && trackRef.current && !props.hideWave) {
			const wavesurfer = WaveSurfer.create({
				...props.waveStyles,
				container: "#waveform",
				responsive: true,
				backend: "MediaElement",
			});
			// Load the waveForm json if provided
			props.waveJson
				? wavesurfer.load(trackRef.current)
				: wavesurfer.load(trackRef.current, props.waveJson);

			wavesurfer.on("ready", () => {
				setWaveSurfer(wavesurfer);
				// Returns the instance to call methods on
				if (typeof props.getWaveSurferInstance === "function") {
					props?.getWaveSurferInstance(waveSurfer);
				}
				wavesurfer.zoom(props.zoom);
			});

			if (props?.events) {
				Object.entries(props.events).map(([key, value]) => {
					waveSurfer.on(key, value);
				});
			}
		}

		const audioElement = trackRef.current;
		const handleTimeUpdate = () => {
			setCurrentTime(audioElement.currentTime);
		};

		const handleDurationChange = () => {
			setDuration(audioElement.duration);
		};

		audioElement.addEventListener("timeupdate", handleTimeUpdate);
		audioElement.addEventListener("durationchange", handleDurationChange);

		return () => {
			waveformRef.current = null;
			audioElement.removeEventListener("timeupdate", handleTimeUpdate);
			audioElement.removeEventListener("durationchange", handleDurationChange);
		};
	}, [props, waveSurfer, trackRef]);

	return (
		<div className="  space-y-4 lg:space-y-0 max-w-[90vw]">
			<div className="flex flex-col flex-grow justify-around ">
				{/* Waveform and audio */}
				<div className="z-0 m-4 border-[3px] border-gradient-to-r from-blue-500 via-blue-600 to-blue-700 p-2 rounded-xl  overflow-hidden ">
					{!props.hideWave && (
						<div
							ref={waveformRef}
							id="waveform"
							className=" rounded-xl  box-border "
						/>
					)}
					<audio
						src={props.audioUrl}
						ref={trackRef}
						className="overflow-hidden"
					/>

					<div className="flex justify-between">
						<span className="text-sm font-medium text- ">
							{formatTime(currentTime)}
						</span>
						<span className="text-sm font-medium text-white">
							{formatTime(duration)}
						</span>
					</div>
				</div>

				{/* Controls */}
				<div className="flex items-center justify-center gap-4 mb-5 mt-2 ">
					<span
						className=" shadow-xl  bg-gray-300 rounded-full p-1  font-medium cursor-pointer hover:text-blue-500 transition"
						onClick={() => changePlaybackSpeed()}
					>
						{playBackSpeedOptions[playBackSpeed]}x
					</span>
					<div className="shadow-xl  bg-gray-100 rounded-full p-2 ">
						<FaUndo
							className="text-xl cursor-pointer hover:text-blue-500 transition"
							onClick={() => seekAudioFifteenSeconds(false)}
						/>
					</div>
					{playingAudio ? (
						<div className="shadow-3xl bg-white rounded-full p-3">
							<FaPause
								className="text-2xl cursor-pointer hover:text-blue-500 transition"
								onClick={() => (playingAudio ? pauseAudio() : playAudio())}
							/>
						</div>
					) : (
						<div className="shadow-3xl  bg-white rounded-full p-3">
							<FaPlay
								className="text-2xl cursor-pointer hover:text-blue-500 transition"
								onClick={() => (playingAudio ? pauseAudio() : playAudio())}
							/>
						</div>
					)}

					<div className="shadow-xl  bg-gray-100 rounded-full p-2 ">
						<FaRedo
							className="text-lg cursor-pointer hover:text-blue-500 transition "
							onClick={() => seekAudioFifteenSeconds(true)}
						/>
					</div>
					<div className="shadow-xl  bg-gray-300 rounded-full py-2 px-1 ">
						<FaTrash
							className="  cursor-pointer text-red-500 hover:text-red-600 transition"
							onClick={() => props.clear()}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}
