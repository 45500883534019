import React from "react";

export default function InsetDividers({ analyzeData, generateData }) {
	return (
		<div className="overflow-auto bg-gray-100 shadow-md rounded-xl md:p-4 p-2 max-h-96">
			<table className="min-w-full text-sm text-left">
				<thead className="bg-gray-200 text-gray-600 uppercase text-xs">
					<tr>
						<th className="px-4 py-3 font-semibold text-gray-700"></th>
						<th className="px-4 py-3 font-semibold text-gray-700">
							Profanity Score
						</th>
						<th className="px-4 py-3 font-semibold text-gray-700">
							Readability
						</th>
						<th className="px-4 py-3 font-semibold text-gray-700">Compound</th>
						<th className="px-4 py-3 font-semibold text-gray-700">Label</th>
						<th className="px-4 py-3 font-semibold text-gray-700">Positive</th>
						<th className="px-4 py-3 font-semibold text-gray-700">Negative</th>
						<th className="px-4 py-3 font-semibold text-gray-700">Neutral</th>
					</tr>
				</thead>
				<tbody>
					{Object.entries(generateData.data.data).map(
						([speaker, details], index) => (
							<tr
								key={speaker}
								className="border-b border-gray-200 hover:bg-gray-200 transition-colors duration-200"
							>
								<td className="px-4 py-3 text-gray-800">{speaker}</td>
								<td className="px-4 py-3 text-gray-600">
									{details.profanity.score}
								</td>
								<td className="px-4 py-3 text-gray-600">
									{details.readability}
								</td>
								<td className="px-4 py-3 text-gray-600">
									{details.sentiment.compound}
								</td>
								<td className="px-4 py-3 text-gray-600">
									{details.sentiment.label}
								</td>
								<td className="px-4 py-3 text-gray-600">
									{details.sentiment.positive}
								</td>
								<td className="px-4 py-3 text-gray-600">
									{details.sentiment.negative}
								</td>
								<td className="px-4 py-3 text-gray-600">
									{details.sentiment.neutral}
								</td>
							</tr>
						)
					)}
				</tbody>
			</table>
		</div>
	);
}
